import {List} from "./List";
import React, {useState} from "react";
import useRequest, {Statuses} from "./use-request";
import {postSubscription} from "./requests";
import {Loading} from "./Loading";
import {ErrorMessage} from "./ErrorMessage";
import {SuccessMessage} from "./SuccessMessage";
import {SubData} from "./models";
import {useTranslation} from 'react-i18next';

interface Props {
  hash: string
  email: string
  data: SubData
}

export function Subscriptions({data, email, hash}: Props) {
  const request = useRequest();
  const [list, setList] = useState(data);
  const {t} = useTranslation();

  const selectAll = () => {
    const list = [...data];
    list.forEach(item => item.subscribed = true);
    setList(list);
  };

  const unselectAll = () => {
    const list = [...data];
    list.forEach(item => item.subscribed = false);
    setList(list);
  };

  const topicKeys = list.filter(item => !item.subscribed).map(item => item.topicKey);

  const save = () => {
    request.send({url: postSubscription, method: 'POST', data: {
      hash,
      email,
      topicKeys
    }});
  };

  return <>
    {request.status === Statuses.init && <>
      <div className="title">
        <div><span>{t('SorryTitle')} </span>{t('Instruction')}</div>
      </div>
      <div className={"border-description"}/>
      <div className={"information"}>
        <div className="description">
          <div>{t('Instruction2')}</div>
          <div><i>{t('Instruction2-note')}</i></div>
        </div>
        <List data={list} changeList={setList}/>
        <div className="list-controls">
          <button className="control" onClick={selectAll}>{t('Select All')}</button>
          <button className="control" onClick={unselectAll}>{t("Unselect All")}</button>
        </div>
        <div className="button-wrapper">
          <button className="save-button" disabled={topicKeys.length === 0} onClick={save}>{t('save')}</button>
        </div>
      </div>
    </>}
    {request.status === Statuses.successful && request.data && <SuccessMessage/>}
    {request.status === Statuses.inProgress && <Loading/>}
    {request.status === Statuses.failed && <ErrorMessage/>}
  </>;
}
