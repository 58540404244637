import React from "react";
import {SubItems} from "./models";

interface Props {
  item: SubItems,
  changeSelection: () => void
}

export function Item({item, changeSelection}: Props) {
  return <div className="item">
    <label>
      <input className="item-checkbox" type="checkbox" checked={item.subscribed} onChange={changeSelection}/>
      <span className="channel-name">{item.title}</span>
    </label>
  </div>
}