import React from "react";
import {useTranslation} from "react-i18next";

export function SuccessMessage() {
  const {t} = useTranslation();
  return <div className="success-message">
    <h1>{t('Success')}</h1>
    <div className="success-text">{t('success text')}</div>
  </div>;
}
