import React from 'react';
import './App.scss';
import useRequest, {Statuses} from "./use-request";
import {Loading} from "./Loading";
import {Subscriptions} from "./Subscriptions";
import {ErrorMessage} from "./ErrorMessage";
import {getSubscription} from "./requests";
import {fromResToSubData} from "./models";
import './i18n';
import {Footer} from "./Footer";

function App() {
  const url = new URL(window.location.href);
  const email = url.searchParams.get('email');
  const hash = url.searchParams.get('hash');
  const cpk = url.searchParams.get('cpk');
  const request = useRequest({url: getSubscription, params: {email, hash, cpk}});
  const data = fromResToSubData(request.data).filter(item => item.subscribed);
  return (
    <div className="app">
      <div className="wrapper">
        <div className="wrapper-logo">
          <div className="logo"/>
        </div>
        <div className="subscription-container">
          {
            request.status === Statuses.inProgress &&
            <Loading/>
          }
          {
            request.status === Statuses.successful && email && hash &&
            <Subscriptions data={data} email={email} hash={hash}/>
          }
          {
            (request.status === Statuses.failed || !hash || !email) &&
            <ErrorMessage />
          }
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
